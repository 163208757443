<template>
  <default-layout>
    <v-container fluid tag="section">
      <page-loading :show="loading || loadingForm" />
      <v-alert type="error" :value="showAlert" dismissible>
        <div v-html="messageAlert" />
      </v-alert>
      <v-row>
        <v-col cols="6">
          <div class="d-flex flex-wrap">
            <client-detail-form
              :detail="detail"
              :permissions="permissions"
              :allowedActions="allowedActions"
              style="width: 100%"
            ></client-detail-form>
          </div>
        </v-col>
        <v-col cols="6">
          <div class="d-flex flex-wrap">
            <assigned-agent-list
              :items="assignedAgents"
              :permissions="permissions"
              :allowedActions="allowedActions"
              style="width: 100%"
            ></assigned-agent-list>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <modal-agent></modal-agent>
  </default-layout>
</template>

<script>
import DefaultLayout from '@/layouts/default';
const PageLoading = () => import('@/components/content-loading/page-loading.vue');
const ClientDetailForm = () =>
  import('@/components/transaction-management/client/client-detail-form');
const AssignedAgentList = () =>
  import('@/components/transaction-management/client/assigned-agent-list');
const ModalAgent = () => import('@/components/modals/modal-agent');
import { mapState } from 'vuex';

export default {
  components: {
    DefaultLayout,
    PageLoading,
    ClientDetailForm,
    AssignedAgentList,
    ModalAgent,
  },
  data() {
    return {
      showAlert: false,
      messageAlert: null,
    };
  },
  computed: {
    ...mapState({
      loading: (state) => state.client.loading,
      loadingForm: (state) => state.client.form.loading,
      permissions: (state) => state.client.permissions,
      allowedActions: (state) => state.client.allowedActions,
      detail: (state) => state.client.detail,
      assignedAgents: (state) => state.client.assignedAgents,
    }),
  },
  async mounted() {
    await this.loadDetail();
  },
  methods: {
    async refreshAction() {
      await this.loadDetail();
    },
    async loadDetail() {
      try {
        this.$store.commit('client/RESET_DEFAULT');
        const response = await this.$store.dispatch(
          'client/getDetailInitData',
          this.$route.params.uuid,
        );
        console.log('DETAIL CLIENT: ', response);
      } catch (e) {
        this.showAlert = true;
        this.messageAlert = e;
      }
    },
  },
};
</script>
